import React from "react";
import { Navbar } from "../../../ui/organisms/navbar";
import NotFoundHero from "../components/NotFoundHero";
import "../index.scss";

export default function NotFound() {
  return (
    <>
      <Navbar />
      <div className="not-found-page">
        <NotFoundHero />
      </div>
    </>
  );
}
