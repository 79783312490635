import React from "react";
import { Anchor } from "../../../ui/atoms/anchor";
import Arrow from "../../../ui/icons/Arrow";

export default function NotFoundHero() {
  return (
    <div className="app-container">
      <div className="app-container__section">
        <div className="not-found-page__hero">
          <div className="not-found-page__hero__text">
            <span className="not-found-page__hero__pre-heading">Error 404</span>
            <h1 className="not-found-page__hero__heading">
              Can't find this page
            </h1>

            <p className="not-found-page__hero__description">
              Sorry the page you're looking for doesn't exist
            </p>

            <Anchor to="/">
              Landing Page
              <Arrow />
            </Anchor>

            <p className="not-found-page__hero__description">
              Let’s go back to where it all started
            </p>
          </div>
          <div className="not-found-page__hero__image">
            <img
              src="https://res.cloudinary.com/groupify/image/upload/v1648292511/website-assets/unsplash_O35rT6OytRo_bignkd.png"
              alt="not found"
            />
          </div>
        </div>

        <div className="not-found-page__rights">
          <p>© 2022 Groupify. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}
